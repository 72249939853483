import * as ApiThrottler from "../../ApiThrottler";
import SplashLogger from "utils/SplashLogger";
var loggerName = "PushEventsToDumpService";
var params;
var DataStore = {};
export function initalizeDumpServiceApiThrottler(contextParams) {
    params = contextParams;
    if (DataStore.apiThrottlerPush) {
        return;
    }
    DataStore.apiThrottlerPush = ApiThrottler.initDumpServiceApiThrottler("MetaSessionEvent", "META_SESSION_EVENT", "web-core-ui", window.webCoreUiRuntimeConfigs.apiThrottler);
}
export function pushEventsViaApiThrottler(_event, properties) {
    var _a, _b;
    DataStore.userAnalyticsId = (_a = window.trackerProperties) === null || _a === void 0 ? void 0 : _a.analyticsId;
    DataStore.eventName = _event;
    DataStore.properties = btoa(unescape(encodeURIComponent(JSON.stringify(properties))));
    DataStore.eventTime = new Date().toISOString();
    DataStore.sessionId = (_b = window.trackerProperties.metaSessionId) === null || _b === void 0 ? void 0 : _b.replace("-", "").toUpperCase();
    var payload = {
        "analytics_id": DataStore.userAnalyticsId,
        "session_id": DataStore.sessionId,
        "event_name": DataStore.eventName,
        "properties": DataStore.properties,
        "event_time": DataStore.eventTime,
    };
    var pushFunction = DataStore.apiThrottlerPush;
    if (pushFunction) {
        try {
            console.group('Datadump MetaSessionEvent dispatched.');
            console.info(payload.event_name);
            console.groupEnd();
            SplashLogger.debug("Datadump MetaSessionEvent dispatched", payload.event_name, JSON.stringify(payload));
        }
        catch (e) {
            console.error('SOMETHING WENT WRONG in logging -- pushEventsViaApiThrottler', e.message);
        }
        pushFunction(payload);
        return;
    }
    else {
        SplashLogger.error(loggerName, "SOMETHING WENT WRONG -- ApiThrottler MetaSessionEvent not yet initialised");
    }
}
