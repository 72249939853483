var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { post } from "utils/api";
import SplashLogger from "utils/SplashLogger";
import { events, routes } from "../helpers/utils/constants";
var loggerName = "MixpanelTrackingPageViewTracker";
var MixpanelTrackingPageView = /** @class */ (function () {
    function MixpanelTrackingPageView() {
        var _this = this;
        this.initalizeElementsForPageViewTracking = function () {
            _this.addMixpanelPageViewElementInDom();
            _this.trackPageLoadEvents();
            _this.addUtmParametersElementInDom();
        };
        this.addMixpanelPageViewElementInDom = function () {
            var _a;
            var analyticsEvent = (_a = window.trackerProperties) === null || _a === void 0 ? void 0 : _a.analyticsEvent;
            if (analyticsEvent && analyticsEvent.name) {
                if (!analyticsEvent.properties) {
                    analyticsEvent.properties = {};
                }
                analyticsEvent.properties.user_session_id = window.trackerProperties.userSessionId;
                var link = document.createElement('a');
                link.href = 'javascript:void(0)';
                link.id = 'mixpanel-tracking-page-view';
                link.setAttribute('data-name', analyticsEvent.name);
                link.setAttribute('data-properties', JSON.stringify(analyticsEvent.properties));
                document.body.appendChild(link);
            }
        };
        this.addUtmParametersElementInDom = function () {
            var _a;
            var utm_properties_json = ((_a = window.trackerProperties) === null || _a === void 0 ? void 0 : _a.utmProperties) || JSON.stringify({});
            var link = document.createElement('a');
            link.href = 'javascript:void(0)';
            link.id = 'mixpanel-utm-properties';
            link.setAttribute('data-properties', utm_properties_json);
            link.style.display = 'none';
            document.body.appendChild(link);
        };
        this.trackPageLoadEvents = function () {
            if (document.querySelector('#mixpanel-tracking-page-view') === null) {
                return;
            }
            // This function is used to track the explicit page load like 'Landed on Website' event *importantly* before the regular ones like 'Visited Home Page'.
            _this.trackLandedOnWebsite();
            var object_id = document.querySelector('#mixpanel-tracking-page-view_for_unsupported_browser') !== null
                ? '#mixpanel-tracking-page-view_for_unsupported_browser'
                : '#mixpanel-tracking-page-view';
            _this.trackPageLoadEventForEachObject(object_id);
        };
        this.resetLandedWebsiteFlagOnWeb = function () { return __awaiter(_this, void 0, void 0, function () {
            var postRequestConfig, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // remove this callback
                        SplashLogger.debug(loggerName, "Resetting variable");
                        postRequestConfig = {
                            headers: {
                                Accept: "*/*",
                                "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
                                "X-Requested-With": "XMLHttpRequest"
                            }
                        };
                        return [4 /*yield*/, post(routes.RESET_LANDED_WEBSITE_EVENT_FLAG, null, postRequestConfig)];
                    case 1:
                        response = _a.sent();
                        if (response.status == 200) {
                            window.jsSentLandedEvent = 1;
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        this.trackLandedOnWebsite = function () { return __awaiter(_this, void 0, void 0, function () {
            var sendLandedEvent;
            return __generator(this, function (_a) {
                sendLandedEvent = window.trackerProperties.firstTimeVisitForAnalyticsEvent;
                if (sendLandedEvent && typeof window.jsSentLandedEvent === 'undefined') {
                    window.trackAnalyticsEventCore(events.LANDED_ON_WEBSITE, null, false);
                    this.resetLandedWebsiteFlagOnWeb();
                }
                return [2 /*return*/];
            });
        }); };
        this.trackPageLoadEventForEachObject = function (object_id) {
            var _event = {
                name: 'Visited ' + document.querySelector(object_id).getAttribute('data-name'),
                properties: {}
            };
            var properties_object = JSON.parse(document.querySelector(object_id).getAttribute('data-properties'));
            if (properties_object !== null) {
                Object.keys(properties_object).forEach(function (key) {
                    _event.properties[key] = properties_object[key];
                });
            }
            window.trackAnalyticsEventCore(_event.name, _event.properties);
        };
        window.trackPageLoadEventsCore = this.trackPageLoadEvents;
    }
    return MixpanelTrackingPageView;
}());
export { MixpanelTrackingPageView };
