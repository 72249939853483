var Wootric = /** @class */ (function () {
    function Wootric() {
        this.init = function () {
            var _a;
            var wootricSettings = {
                account_token: 'NPS-18e67012',
                created_at: (_a = new Date(window.trackerProperties.createdAt)) === null || _a === void 0 ? void 0 : _a.getSeconds(),
                properties: {
                    analytics_id: window.trackerProperties.analyticsId,
                },
            };
            window.wootricSettings = wootricSettings;
            window.wootric('run');
        };
        this.init();
    }
    return Wootric;
}());
export { Wootric };
