var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { post } from "utils/api";
import { routes } from "../helpers/utils/constants";
import SplashLogger from "utils/SplashLogger";
import { getCookie } from "utils/index";
import { isStudent } from "../helpers/utils";
import * as Sentry from "@sentry/browser";
// this module is just to initialize GA for it to be able to send GA Client ID to backend
// via API. This module is not used to send any events from FE
var GoogleAnalytics = /** @class */ (function () {
    function GoogleAnalytics(params) {
        var _this = this;
        this.init = function (params) {
            var _a;
            if (WebCoreUIConfig.eventTrackers.ga.enabled && ((_a = window.trackerProperties) === null || _a === void 0 ? void 0 : _a.analyticsEnabled)) {
                _this.setGaConfigs();
                _this.setNonStudentDimensions();
                if (isStudent()) {
                    _this.setStudentDimensions(params);
                }
                window.ga('send', 'pageview');
                _this.updateGaClientId();
            }
            else {
                SplashLogger.warn("GA tracking not enabled");
            }
        };
        this.setGaConfigs = function () {
            window.ga('create', window.webCoreUiRuntimeConfigs.thirdPartyTrackers.gaAccount, 'auto', { 'allowLinker': true }, { 'cookieExpires': 60 * 60 * 24 * 365 }, { 'useAmpClientId': true });
            window.ga('require', 'linker');
            var autoLinkDomains = [];
            if (WebCoreUIConfig.env == "development") {
                if (location.hostname.search('localhost') != -1)
                    // add this for local testing only.
                    autoLinkDomains = ['customlocal'];
                else
                    autoLinkDomains = ['localhost'];
            }
            else {
                if (location.hostname.search('splashmath') != -1)
                    autoLinkDomains = ['splashlearn.com'];
                else
                    autoLinkDomains = ['splashmath.com'];
            }
            window.ga('linker:autoLink', autoLinkDomains);
            window.ga('require', 'linkid');
            window.ga('require', 'ecommerce');
            window.ga('set', 'anonymizeIp', true);
            window.ga('set', 'allowAdFeatures', false);
            var gaReferrerSet = getCookie('ga_referrer_set');
            if (gaReferrerSet) {
                window.ga('set', 'referrer', gaReferrerSet);
            }
        };
        this.setStudentDimensions = function (params) {
            var _a;
            var currentUser = params.user;
            if (!(currentUser === null || currentUser === void 0 ? void 0 : currentUser.guardian)) {
                SplashLogger.error("Guardian context data not received in API response"); // possible in cases of API failure
                Sentry.captureMessage("Guardian context data not received in API response", {
                    level: "warning",
                    tags: {
                        analyticsId: (_a = window.trackerProperties) === null || _a === void 0 ? void 0 : _a.analyticsId
                    }
                });
                return;
            }
            window.ga('set', 'dimension4', currentUser.guardian.analyticsId);
            window.ga('set', 'dimension4', currentUser.guardian.signupFlow);
            window.ga('set', 'dimension19', currentUser.guardian.typeOfDashboard);
            if (currentUser.guardian.schoolId) {
                window.ga('set', 'dimension7', currentUser.guardian.schoolId);
            }
            if (currentUser.guardian.isPaid) {
                window.ga('set', 'dimension8', currentUser.guardian.isPaid);
            }
        };
        this.setNonStudentDimensions = function () {
            var _a, _b, _c;
            // dimension1 = User Type
            if ((_a = window.trackerProperties) === null || _a === void 0 ? void 0 : _a.set_user_type_for_google_analytics) {
                window.ga('set', 'dimension1', getCookie("sm_user_type_for_google_analytics"));
            }
            // dimension2 = Product Type
            if ((_b = window.trackerProperties) === null || _b === void 0 ? void 0 : _b.set_product_type_for_google_analytics) {
                window.ga('set', 'dimension2', getCookie("sm_product_type_for_google_analytics"));
            }
            // dimension3 = Product Subscription
            if ((_c = window.trackerProperties) === null || _c === void 0 ? void 0 : _c.set_product_subscription_for_google_analytics) {
                window.ga('set', 'dimension3', getCookie("sm_product_subscription_for_google_analytics"));
            }
        };
        this.updateGaClientId = function () {
            window.ga(function (tracker) {
                var _a, _b;
                return __awaiter(this, void 0, void 0, function () {
                    var clientId, postRequestConfig, response;
                    return __generator(this, function (_c) {
                        switch (_c.label) {
                            case 0:
                                clientId = tracker.get('clientId') || ((_a = window.trackerProperties) === null || _a === void 0 ? void 0 : _a.existingClientId);
                                document.cookie = "ga_client_id" + "=" + clientId + "; max-age=" + 3600 + "; path=/";
                                if (!(((_b = window.trackerProperties) === null || _b === void 0 ? void 0 : _b.existingClientId) != clientId)) return [3 /*break*/, 2];
                                postRequestConfig = {
                                    headers: {
                                        Accept: "*/*",
                                        "Content-Type": "application/json",
                                        "X-Requested-With": "XMLHttpRequest"
                                    }
                                };
                                return [4 /*yield*/, post(routes.UPDATE_GA_CLIENT_ID, JSON.stringify({ client_id: clientId }), postRequestConfig)];
                            case 1:
                                response = _c.sent();
                                if (response.status == 200) {
                                    window.jsSentLandedEvent = 1;
                                }
                                _c.label = 2;
                            case 2: return [2 /*return*/];
                        }
                    });
                });
            }, null);
        };
        this.init(params);
    }
    return GoogleAnalytics;
}());
export { GoogleAnalytics };
