export function checkAndSetAdwordsUtmParams() {
    var query_params = window.location.search.substr(1).split('&');
    if (query_params[0]) {
        query_params.forEach(function (element) {
            if (element.split('adCampaign=')[1]) {
                window.sm_addword_campaign = element.split('adCampaign=')[1];
                window.sm_addword_utms = true;
            }
            else if (element.split('adGroup=')[1]) {
                window.sm_addword_group = element.split('adGroup=')[1];
                window.sm_addword_utms = true;
            }
            else if (element.split('adTag=')[1]) {
                window.sm_addword_tag = element.split('adTag=')[1];
                window.sm_addword_utms = true;
            }
        });
    }
}
