import avo from '../vendors/avo';
import AvoDestination from '../vendors/avo-destination';
var Avo = /** @class */ (function () {
    function Avo() {
        this.init = function () {
            window.sendEventViaAvo = function (methodName, params) {
                var environment = WebCoreUIConfig.env == 'production' ? 'prod' : 'dev';
                var payload = params;
                try {
                    payload = params && JSON.parse(params);
                }
                catch (e) { }
                if (methodName) {
                    try {
                        avo.initAvo({ env: environment }, {}, {}, AvoDestination);
                        avo.setSystemProperties({ screenHeight: window.innerHeight });
                        avo[methodName](payload);
                    }
                    catch (error) {
                        console.error(error);
                    }
                }
            };
        };
        this.init();
    }
    return Avo;
}());
export { Avo };
