import * as ApiThrottler from "../../ApiThrottler";
import SplashLogger from "utils/SplashLogger";
import { routes } from "./utils/constants";
var loggerName = "BufferTrackGuestEventsApi";
var BufferTrackGuestEvents = /** @class */ (function () {
    function BufferTrackGuestEvents() {
        var _this = this;
        this.initalizeTrackGuestEventsApiThrottler = function () {
            if (_this.apiThrottlerPushFunction) {
                return;
            }
            var transformFunction = function (payloadArray) {
                var _a;
                var data = {
                    records: payloadArray,
                    created_at: new Date().toISOString(),
                    guestId: (_a = window.trackerProperties) === null || _a === void 0 ? void 0 : _a.guestId
                };
                return JSON.stringify(data);
            };
            _this.apiThrottlerPushFunction = ApiThrottler.initializeAndGetPushFunction("TrackGuestEvents", WebCoreUIConfig.services.AnalyticsService.host + routes.TRACK_GUEST_EVENTS, transformFunction);
        };
        this.initalizeTrackGuestEventsApiThrottler();
    }
    BufferTrackGuestEvents.prototype.pushTrackGuestEventsViaApiThrottler = function (data) {
        var _a, _b, _c, _d, _e;
        var payload = {
            user_id: (_a = window.trackerProperties) === null || _a === void 0 ? void 0 : _a.guestId,
            client_id: (_b = window.trackerProperties) === null || _b === void 0 ? void 0 : _b.clientId,
            event_name: data.event_name,
            properties: data.properties,
            meta: {
                send_clevertap: (_c = data.meta) === null || _c === void 0 ? void 0 : _c.send_clevertap,
                send_mixpanel: (_d = data.meta) === null || _d === void 0 ? void 0 : _d.send_mixpanel,
                send_datadump: (_e = data.meta) === null || _e === void 0 ? void 0 : _e.send_datadump,
                ue_source: "web-core-ui # TrackGuestEvent",
            },
            timestamp: data.properties["time"]
        };
        if (this.apiThrottlerPushFunction) {
            this.apiThrottlerPushFunction(payload);
            try {
                // Adding console logs additionally due to requirement from product team
                console.group('GUEST EVENT DISPATCHED (server-side)');
                console.info(data.event_name);
                console.info(data.properties);
                console.groupEnd();
                SplashLogger.debug(loggerName, 'GUEST EVENT DISPATCHED (server-side)', {
                    event: data.event_name,
                    properties: data.properties
                });
            }
            catch (err) {
                console.error('SOMETHING WENT WRONG in logging -- trackEventsForGuests', err.message);
            }
            return;
        }
        else {
            SplashLogger.error(loggerName, "SOMETHING WENT WRONG -- ApiThrottler trackEventsForGuests not yet initialised");
        }
    };
    return BufferTrackGuestEvents;
}());
export { BufferTrackGuestEvents };
