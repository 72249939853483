var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { getAllCookies } from ".";
import SplashLogger from "./SplashLogger";
import * as Sentry from "@sentry/browser";
import { sleep } from "libs/Trackers/helpers/utils";
var loggerName = 'APIModule';
export var get = function (url, config) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetch(url, {
                    method: "GET",
                    mode: "cors",
                    credentials: "include",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                })];
            case 1:
                response = _a.sent();
                if (!(!response || !response.ok)) return [3 /*break*/, 9];
                if (!(config === null || config === void 0 ? void 0 : config.retry)) return [3 /*break*/, 7];
                if (!((response === null || response === void 0 ? void 0 : response.status) > 500 || (response === null || response === void 0 ? void 0 : response.status) == 404)) return [3 /*break*/, 5];
                console.log("Retry on failure: ", JSON.stringify(config));
                if (!(config.backOffSecs <= config.maxBackOffSecs)) return [3 /*break*/, 3];
                if (config.beforeRetryCallback) {
                    try {
                        config.beforeRetryCallback(response);
                    }
                    catch (e) {
                        Sentry.captureException(e);
                    }
                }
                return [4 /*yield*/, sleep(config.backOffSecs)];
            case 2:
                _a.sent();
                config.backOffSecs = config.backOffSecs * 2;
                get(url, config);
                return [3 /*break*/, 4];
            case 3:
                reportNon2xxResponseToSentry({ response: response, skip401Errors: config === null || config === void 0 ? void 0 : config.skipLogging401Errors });
                return [2 /*return*/, response];
            case 4: return [3 /*break*/, 6];
            case 5:
                reportNon2xxResponseToSentry({ response: response, skip401Errors: config === null || config === void 0 ? void 0 : config.skipLogging401Errors });
                _a.label = 6;
            case 6: return [3 /*break*/, 8];
            case 7:
                reportNon2xxResponseToSentry({ response: response, skip401Errors: config === null || config === void 0 ? void 0 : config.skipLogging401Errors });
                return [2 /*return*/, response];
            case 8: return [3 /*break*/, 11];
            case 9:
                if (!(config === null || config === void 0 ? void 0 : config.forceRetry)) return [3 /*break*/, 11];
                console.log("Force Retry: ", JSON.stringify(config));
                if (!(config.backOffSecs <= config.maxBackOffSecs)) return [3 /*break*/, 11];
                return [4 /*yield*/, sleep(config.backOffSecs)];
            case 10:
                _a.sent();
                config.backOffSecs = config.backOffSecs * 2;
                get(url, config);
                _a.label = 11;
            case 11: return [2 /*return*/, response];
        }
    });
}); };
var defaultPostHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
};
// not including retry logic here as post request should be retried only if idompotent.
export var post = function (url, body, config) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, res;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                headers = __assign(__assign({}, defaultPostHeaders), ((config === null || config === void 0 ? void 0 : config.headers) || {}));
                return [4 /*yield*/, fetch(url, {
                        method: "POST",
                        mode: "cors",
                        credentials: "include",
                        headers: headers,
                        body: body
                    })];
            case 1:
                res = _a.sent();
                if (!res || !res.ok) {
                    reportNon2xxResponseToSentry({ response: res });
                }
                return [2 /*return*/, res];
        }
    });
}); };
var reportNon2xxResponseToSentry = function (_a) {
    var response = _a.response, skip401Errors = _a.skip401Errors;
    SplashLogger.error(loggerName, "Non 2xx response received. URL: ".concat(response === null || response === void 0 ? void 0 : response.url, ", Status Code: ").concat(response === null || response === void 0 ? void 0 : response.status));
    // In case of analytics service user context call, these cases are already logged in BE to auditEvent
    if (skip401Errors && (response === null || response === void 0 ? void 0 : response.status) == 401) {
        return;
    }
    Sentry.setContext('user-context', {
        'cookies': getAllCookies()
    });
    Sentry.captureMessage('Non 2xx status in calling API: ' + (response === null || response === void 0 ? void 0 : response.status), {
        extra: {
            url: response === null || response === void 0 ? void 0 : response.url,
            status: response === null || response === void 0 ? void 0 : response.status,
            body: response === null || response === void 0 ? void 0 : response.body,
        },
        level: "error"
    });
};
