var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import SplashLogger from "utils/SplashLogger";
import { events, routes } from "../helpers/utils/constants";
import { post } from "utils/api";
import * as Sentry from "@sentry/browser";
var loggerName = "GTMTracker";
var GTM = /** @class */ (function () {
    function GTM() {
        var _this = this;
        this.init = function () { };
        this.trackToGtm = function (dataLayer, eventName, category, properties) {
            if (properties === void 0) { properties = { revenue: 0, label: undefined }; }
            if (!window.trackerProperties.analyticsEnabled) {
                return;
            }
            if (typeof dataLayer !== 'undefined') {
                try {
                    // Adding console logs additionally due to requirement from product team
                    console.group('GTM EVENT DISPATCHED');
                    console.info(eventName);
                    console.groupEnd();
                    SplashLogger.debug(loggerName, 'GTM EVENT DISPATCHED', eventName);
                }
                catch (err) {
                    console.error('SOMETHING WENT WRONG in logging -- trackToGtm', err.message);
                }
                var gtmUserId = null;
                if (["parent", "teacher"].includes(window.trackerProperties.userType)) {
                    gtmUserId = window.trackerProperties.analyticsId;
                }
                if (gtmUserId) {
                    dataLayer.push(__assign({ 'event': eventName, 'category': category, 'label': properties.label, 'ecomm_totalvalue': properties.revenue || 0, 'user_id': gtmUserId }, properties));
                }
                else {
                    dataLayer.push(__assign({ 'event': eventName, 'category': category, 'label': properties.label, 'ecomm_totalvalue': properties.revenue || 0 }, properties));
                }
                return dataLayer;
            }
        };
        this.trackTransactionEventToGtm = function (eventKey) { return __awaiter(_this, void 0, void 0, function () {
            var response, data, error_1, e;
            var _this = this;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (window.trackerProperties.isEuropeIp) {
                            return [2 /*return*/];
                        }
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 6, , 7]);
                        return [4 /*yield*/, post(routes.META_DATA_ACTION, JSON.stringify({
                                event_key: eventKey
                            }))];
                    case 2:
                        response = _b.sent();
                        if (!(response.status === 200)) return [3 /*break*/, 4];
                        return [4 /*yield*/, response.json()];
                    case 3:
                        data = _b.sent();
                        try {
                            data.map(function (event) {
                                if (event.name === events.TRANSACTION) {
                                    // to be added in https://studypad.atlassian.net/browse/SL-10972
                                    _this.trackToGtm(window.dataLayer, 'Paid Parent', 'Paid Parent', { revenue: event.properties.payment_after_discount, label: undefined });
                                }
                            });
                        }
                        catch (err) {
                            SplashLogger.error(loggerName, 'Error in trackTransactionEventToGtm', err);
                            Sentry.captureException(err, {
                                tags: { analyticsId: (_a = window.trackerProperties) === null || _a === void 0 ? void 0 : _a.analyticsId },
                                level: "error",
                            });
                        }
                        SplashLogger.debug(loggerName, data);
                        return [3 /*break*/, 5];
                    case 4:
                        if (response.status >= 400) {
                            SplashLogger.warn(loggerName, response.statusText);
                            Sentry.captureMessage("Non-200 status response: Status code -> " + response.status, "error");
                        }
                        _b.label = 5;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        error_1 = _b.sent();
                        e = error_1;
                        SplashLogger.error(loggerName, 'Error in trackTransactionEventToGtm', e);
                        Sentry.captureException(error_1, {
                            tags: { event: eventKey },
                            level: "error",
                        });
                        return [2 /*return*/, console && console.warn(e)];
                    case 7: return [2 /*return*/];
                }
            });
        }); };
        this.init();
        window.trackToGtmCore = this.trackToGtm;
        window.trackTransactionEventToGtmCore = this.trackTransactionEventToGtm;
    }
    return GTM;
}());
export { GTM };
