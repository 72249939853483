export var events = {
    VISITED_SIGNUP_SCREEN: 'Visited Signup Screen',
    ACCOUNT_TYPE_SCREEN_SHOWN: 'Account Type Screen Shown',
    CLICKED_SIGNUP: 'Clicked Signup',
    CLICKED_SIGNUP_POPUP: 'Clicked Signup Popup',
    CLICKED_LOGIN: 'Clicked Login',
    VISITED_PARENT_LANDING: 'Visited Parent Landing',
    VISITED_PRODUCT_PURCHASE_FORM: 'Visited Product Purchase Form',
    PARENT_PRODUCT_EVENTS: 'parent product events',
    PLAYABLE_STARTED: "Playable Started",
    PLAYABLE_CLICKED: "Playable Clicked",
    LANDED_ON_WEBSITE: 'Landed on Website',
    TRANSACTION: 'Transaction'
};
export var routes = {
    USER_EVENT_TRACK: "/web_api/user_analytics/v1/track",
    TRACK_GUEST_EVENTS: "/api/v1/track_guest_events",
    META_DATA_ACTION: "/user_events/metadata_for_action.json",
    USER_CONTEXT_WEB: "/web_api/user_analytics/v1/user_context",
    USER_CONTEXT_AS: "/api/v1/user_context",
    UPDATE_GA_CLIENT_ID: "/user/user_properties",
    RESET_LANDED_WEBSITE_EVENT_FLAG: "/user/user_properties/reset_landed_website_event_flag",
    GET_SERVER_TIME: "/api/v3/utc.json"
};
export var trackers = {
    SL_EVENT_TRACKERS: "slEventTrackers",
    MIXPANEL: "mixpanel",
    HOTJAR: "hotjar",
    MIXPANEL_PAGE_VIEW: "mixpanelPageView",
    GTM: "gtm",
    GA: "ga",
    WOOTRIC: "wootric"
};
export var CONSTANTS = {
    minimumThresholdForSystemMismatchPropMs: 2000 // 2 seconds
};
