var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import SplashLogger from "utils/SplashLogger";
import { isParent, isTeacher } from "../helpers/utils";
import * as Sentry from '@sentry/browser';
var loggerName = 'Hotjar';
export var Hotjar = /** @class */ (function () {
    function Hotjar(params) {
        var _this = this;
        this.isTrackingEnabled = function () {
            var _a, _b, _c;
            return ((_b = (_a = window.webCoreUiRuntimeConfigs) === null || _a === void 0 ? void 0 : _a.thirdPartyTrackers) === null || _b === void 0 ? void 0 : _b.hotjarSiteId) && ((_c = window.trackerProperties) === null || _c === void 0 ? void 0 : _c.analyticsEnabled);
        };
        this.isHotjarAvailable = function () {
            return typeof window.hj !== 'undefined';
        };
        this.getSuperPropertiesForHotjar = function () {
            var _a;
            var experimentAssigned = ((_a = _this.params.superProperties) === null || _a === void 0 ? void 0 : _a['Experiments Assigned']) || [];
            var abTestAttributes = _this.buildAttributesObject(experimentAssigned);
            if (isTeacher()) {
                return __assign({}, abTestAttributes);
            }
            if (isParent()) {
                return __assign({}, abTestAttributes);
            }
            return {};
        };
        this.buildAttributesObject = function (experimentAssigned) {
            return experimentAssigned.reduce(function (acc, key) {
                var parts = key.split('-'); // Split by hyphen to separate key and value
                var prefixedKey;
                if (parts.length > 1) {
                    // Handle cases with hyphens
                    var cleanKey = parts[0];
                    var value = parts.slice(1).join('-');
                    prefixedKey = "".concat(Hotjar.ABTEST_PREFIX).concat(cleanKey);
                    if (Hotjar.ABTEST_KEYS.includes(cleanKey)) {
                        acc[prefixedKey] = value;
                    }
                }
                else {
                    // Handle cases without hyphens
                    var cleanKey = key;
                    prefixedKey = "".concat(Hotjar.ABTEST_PREFIX).concat(cleanKey);
                    if (Hotjar.ABTEST_KEYS.includes(cleanKey)) {
                        acc[prefixedKey] = true;
                    }
                }
                return acc;
            }, {});
        };
        this.checkAttributeLimit = function (attributes) {
            var attributeCount = Object.keys(attributes).length;
            if (attributeCount >= Hotjar.ATTRIBUTE_LIMIT_THRESHOLD) {
                Sentry.captureMessage("Warning: Number of A/B test attributes is approaching the Hotjar limit of ".concat(Hotjar.ATTRIBUTE_LIMIT_THRESHOLD, " keys."), {
                    level: 'warning',
                    extra: {
                        attributeCount: attributeCount,
                        url: window.location.href
                    }
                });
                SplashLogger.warn(loggerName, "Warning: Number of A/B test attributes is ".concat(attributeCount, ", approaching the Hotjar limit of ").concat(Hotjar.ATTRIBUTE_LIMIT_THRESHOLD, " keys."));
            }
        };
        this.identify = function (clientProperties) {
            var _a, _b;
            if (!_this.isTrackingEnabled())
                return;
            if (!_this.isHotjarAvailable())
                return;
            var userId = ((_b = (_a = window.webCoreUiRuntimeConfigs) === null || _a === void 0 ? void 0 : _a.thirdPartyTrackers) === null || _b === void 0 ? void 0 : _b.hotjarSiteId) || null;
            if (isParent() || isTeacher()) {
                var properties = __assign(__assign({}, _this.getSuperPropertiesForHotjar()), (clientProperties || {}));
                if (Object.keys(properties).length === 0)
                    return;
                _this.checkAttributeLimit(properties);
                window.hj('identify', userId, properties);
            }
        };
        this.sendEvent = function (eventName) {
            if (!_this.isTrackingEnabled())
                return;
            if (!_this.isHotjarAvailable())
                return;
            if (!eventName)
                return;
            if (isParent() || isTeacher()) {
                window.hj('event', eventName);
            }
        };
        this.params = params;
        window.hotjarIdentifyCore = this.identify;
        window.hotjarSendEventCore = this.sendEvent;
    }
    Hotjar.ATTRIBUTE_LIMIT_THRESHOLD = 90;
    Hotjar.ABTEST_PREFIX = "abtest_";
    Hotjar.ABTEST_KEYS = WebCoreUIConfig.eventTrackers.hotjar.abTestKeys || [];
    return Hotjar;
}());
