import * as Sentry from "@sentry/browser";
// Example: Destination Interface for the Mixpanel SDK. Replace the Mixpanel implementation with your own tracking SDK methods
const destinationInterface = {

  make: function(env, apiKey) {
    // TODO: Replace the call below with a call to your destination
    // Note: Initializing your destination in the make method is optional, you can skip it if you've already initialized your destination
  },

  logEvent: function(eventName, eventProperties) {
    // TODO: Replace the call below with a call to your destination
    trackAnalyticsEvent(eventName, eventProperties);
    // mixpanel.track(eventName, eventProperties);
  },

  setUserProperties: function(userId, userProperties) {
    // TODO: Replace the call below with a call to your destination
    reportSkippedMethodsIfInvoked("setUserProperties", {userId, userProperties});
  },

  identify: function(userId) {
    // TODO: Replace the call below with a call to your destination
    reportSkippedMethodsIfInvoked("identify", {userId});
  },

  unidentify: function() {
    // TODO: Replace the call below with a call to your destination
    reportSkippedMethodsIfInvoked("unidentify");
  },

  revenue: function(amount, eventProperties) {
    // TODO: Replace the call below with a call to your destination
    reportSkippedMethodsIfInvoked("revenue", {amount, eventProperties});
  },

  page: function(pageName, eventProperties) {
    // TODO: Replace the call below with a call to your destination
    // Note: Mixpanel does not provide a native method for page or screen tracking, so we send an event instead. Other SDKs may have a dedicated page tracking method.
    reportSkippedMethodsIfInvoked("page", {pageName, eventProperties});
  },

  // The following methods are used for group analytics and are not required
  setGroupProperties(groupType, groupId, groupProperties) {
    // TODO: Replace the call below with a call to your destination
    reportSkippedMethodsIfInvoked("setGroupProperties", {groupType, groupId, groupProperties});
  },

  addCurrentUserToGroup: function (groupType, groupId, groupProperties) {
    // TODO: Replace the call below with a call to your destination
    reportSkippedMethodsIfInvoked("addCurrentUserToGroup", {groupType, groupId, groupProperties});
  },

  logEventWithGroups: function (eventName, eventProperties, groupTypesToGroupIds) {
    // TODO: Replace the call below with a call to your destination
    reportSkippedMethodsIfInvoked("logEventWithGroups", {eventName, eventProperties, groupTypesToGroupIds});
  },

};

const reportSkippedMethodsIfInvoked = (methodName,extras={}) => {
  // Currently avo.js only calls make and logEvent callbacks. In case skipped callbacks get fired due to any reason we will be notified.
  Sentry.captureMessage(`Avo invoked a skipped method: ${methodName}`, {
    level: "warning",
    extra: {...extras},
  });
}

export default destinationInterface;