var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { get } from "utils/api";
import * as Sentry from "@sentry/browser";
import { routes } from "../utils/constants";
import SplashLogger from "utils/SplashLogger";
import { getAllCookies } from "utils/index";
import { sleep } from "../utils";
var loggerName = 'UserContext';
// Singleton class to be used as a user context datastore in all scripts.
var UserContext = /** @class */ (function () {
    function UserContext() {
    }
    UserContext.prototype.UserContext = function () { };
    UserContext.initialize = function () {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function () {
            var userContext, jsonResponse, analyticsServiceApiRequestConfig, webServiceApiRequestConfig, analyticsServiceApiUrl, analyticsResponse, analyticsJsonResponse, e_1, currentDateTime, tenMinutesAgoDateTime, userCreatedAtDateTime, superProperties, experimentsAssigned, userType, response, err_1;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        userContext = new UserContext();
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 18, , 19]);
                        jsonResponse = void 0;
                        analyticsServiceApiRequestConfig = void 0;
                        if (!window.trackerModulesAutoReload) return [3 /*break*/, 3];
                        // analyticsServiceApiRequestConfig = {
                        //     retry: true,
                        //     maxBackOffSecs: window.trackerModulesReloadMaxBackOff,
                        //     backOffSecs: 1,
                        //     forceRetry: true,
                        // };
                        analyticsServiceApiRequestConfig = {
                            retry: false,
                        };
                        return [4 /*yield*/, sleep(5)];
                    case 2:
                        _d.sent(); // wait for 5 seconds for properties to update in BE before making API call
                        return [3 /*break*/, 4];
                    case 3:
                        analyticsServiceApiRequestConfig = {
                            retry: true,
                            maxBackOffSecs: 8,
                            backOffSecs: 1,
                            beforeRetryCallback: function (res) { return (window.trackerModulesBufferReason = "analyticsApiCallRetry"); },
                            skipLogging401Errors: true,
                        };
                        _d.label = 4;
                    case 4:
                        webServiceApiRequestConfig = {
                            retry: true,
                            maxBackOffSecs: 8,
                            backOffSecs: 1,
                            beforeRetryCallback: function (res) { return (window.trackerModulesBufferReason = "webApiCallRetry"); },
                            skip401Errors: true,
                        };
                        if (!window.eventsPipeline) return [3 /*break*/, 14];
                        SplashLogger.debug(loggerName, "Calling analytics user context api");
                        analyticsServiceApiUrl = window.webCoreUiRuntimeConfigs.thirdPartyTrackers.analyticsServiceBaseUrl +
                            routes.USER_CONTEXT_AS +
                            "/" +
                            window.trackerProperties.userId;
                        return [4 /*yield*/, get(analyticsServiceApiUrl, analyticsServiceApiRequestConfig)];
                    case 5:
                        analyticsResponse = _d.sent();
                        if (!analyticsResponse || !analyticsResponse.ok) {
                            // API call failed. return empty object
                            SplashLogger.error(loggerName, "Analytics Service Context API Request failed.", JSON.stringify(analyticsResponse));
                            userContext.params = { userContextApiError: 'AnalyticsService Ctx Api Fail' };
                            return [2 /*return*/, userContext];
                        }
                        analyticsJsonResponse = void 0;
                        _d.label = 6;
                    case 6:
                        _d.trys.push([6, 8, , 9]);
                        return [4 /*yield*/, analyticsResponse.json()];
                    case 7:
                        analyticsJsonResponse = _d.sent();
                        if (!analyticsJsonResponse) {
                            SplashLogger.error(loggerName, "Analytics Service Context JSON API Response Empty.", analyticsResponse);
                            throw new Error("Analytics Service Context JSON API Response Empty.");
                        }
                        return [3 /*break*/, 9];
                    case 8:
                        e_1 = _d.sent();
                        SplashLogger.error(loggerName, "Analytics Service Context JSON API Error.", analyticsResponse);
                        if (window.trackerProperties.createdAt) {
                            currentDateTime = new Date();
                            tenMinutesAgoDateTime = currentDateTime.setMinutes(currentDateTime.getMinutes() - 10);
                            userCreatedAtDateTime = new Date(window.trackerProperties.createdAt).getTime();
                            // throw error (and report to sentry) only if 
                            // user has not been created in last 10 minutes
                            if (userCreatedAtDateTime < tenMinutesAgoDateTime) {
                                throw e_1;
                            }
                            else {
                                userContext.params = { userContextApiError: e_1.toString() };
                                return [2 /*return*/, userContext];
                            }
                        }
                        else {
                            throw e_1;
                        }
                        return [3 /*break*/, 9];
                    case 9:
                        // api call successful without errors
                        if (window.trackerModulesAutoReload)
                            window.trackerModulesAutoReload = false;
                        superProperties = analyticsJsonResponse.superProperties;
                        experimentsAssigned = void 0;
                        if (!superProperties) return [3 /*break*/, 12];
                        userType = (_a = analyticsJsonResponse.user) === null || _a === void 0 ? void 0 : _a.type;
                        if (!(userType && userType != "guest")) return [3 /*break*/, 11];
                        experimentsAssigned = superProperties["Experiments Assigned"];
                        if (!(!experimentsAssigned || experimentsAssigned.length == 0)) return [3 /*break*/, 11];
                        window.trackerModulesBufferReason = "abTestApiCallRetry";
                        return [4 /*yield*/, retryUntilAbTestsReceieved(userContext, 0, analyticsResponse)];
                    case 10:
                        analyticsJsonResponse = _d.sent();
                        _d.label = 11;
                    case 11: return [3 /*break*/, 13];
                    case 12:
                        console.warn("Super Properties not found.", analyticsJsonResponse);
                        _d.label = 13;
                    case 13:
                        userContext.params = analyticsJsonResponse;
                        return [3 /*break*/, 17];
                    case 14:
                        SplashLogger.debug(loggerName, "Calling web user context api");
                        return [4 /*yield*/, get(routes.USER_CONTEXT_WEB, webServiceApiRequestConfig)];
                    case 15:
                        response = _d.sent();
                        if (!response && !response.ok) {
                            SplashLogger.error(loggerName, "Web Context API Error Failure", response);
                            throw new Error("Web Context API Error.");
                        }
                        return [4 /*yield*/, response.json()];
                    case 16:
                        jsonResponse = _d.sent();
                        if (!jsonResponse || !jsonResponse.params || !jsonResponse.params.user) {
                            SplashLogger.error(loggerName, "Web Context API JSON Error", jsonResponse);
                            throw new Error("Web Context API JSON Error.");
                        }
                        userContext.params = jsonResponse.params;
                        userContext.params.superProperties = (_b = window.trackerProperties) === null || _b === void 0 ? void 0 : _b.superProperties;
                        _d.label = 17;
                    case 17:
                        if (jsonResponse && jsonResponse.params && jsonResponse.params.user) {
                            Sentry.setUser({ id: (_c = window.trackerProperties) === null || _c === void 0 ? void 0 : _c.analyticsId });
                            SplashLogger.debug(loggerName, "User context prepared.");
                        }
                        return [3 /*break*/, 19];
                    case 18:
                        err_1 = _d.sent();
                        SplashLogger.error(loggerName, 'Error in calling context API: ', err_1);
                        Sentry.setContext('user-context', {
                            'cookies': getAllCookies()
                        });
                        Sentry.captureMessage('Error in calling context API: ' + err_1, "error");
                        userContext.params = { userContextApiError: err_1.toString() };
                        return [2 /*return*/, userContext];
                    case 19: return [2 /*return*/, userContext];
                }
            });
        });
    };
    return UserContext;
}());
var retryUntilAbTestsReceieved = function (userContext, i, analyticsJsonResponse) { return __awaiter(void 0, void 0, void 0, function () {
    var retryIntervalSeconds, analyticsServiceApiUrl, analyticsServiceResponse, newAnalyticsJsonResponse, superProperties, experimentsAssigned;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                retryIntervalSeconds = [1, 2, 2, 5, 5, 10];
                console.log(analyticsJsonResponse);
                // retries finished
                if (i >= retryIntervalSeconds.length) {
                    return [2 /*return*/, analyticsJsonResponse];
                }
                return [4 /*yield*/, sleep(retryIntervalSeconds[i])];
            case 1:
                _a.sent();
                analyticsServiceApiUrl = window.webCoreUiRuntimeConfigs.thirdPartyTrackers.analyticsServiceBaseUrl +
                    routes.USER_CONTEXT_AS +
                    "/" +
                    window.trackerProperties.userId;
                return [4 /*yield*/, get(analyticsServiceApiUrl)];
            case 2:
                analyticsServiceResponse = _a.sent();
                // no valid response returned, return original response
                if (!analyticsServiceResponse || !analyticsServiceResponse.ok) {
                    SplashLogger.warn(loggerName, "retryUntilAbTestsReceieved: API call failure.", JSON.stringify(analyticsServiceResponse));
                    return [2 /*return*/, analyticsJsonResponse];
                }
                return [4 /*yield*/, analyticsServiceResponse.json()];
            case 3:
                newAnalyticsJsonResponse = _a.sent();
                if (!newAnalyticsJsonResponse) {
                    SplashLogger.warn(loggerName, "retryUntilAbTestsReceieved: No valid JSONresponse received.", newAnalyticsJsonResponse);
                    return [2 /*return*/, analyticsJsonResponse];
                }
                analyticsJsonResponse = newAnalyticsJsonResponse;
                superProperties = analyticsJsonResponse.superProperties;
                if (superProperties) {
                    experimentsAssigned = superProperties["Experiments Assigned"];
                    if (!experimentsAssigned || experimentsAssigned.length == 0) {
                        return [2 /*return*/, retryUntilAbTestsReceieved(userContext, i + 1, analyticsJsonResponse)];
                    }
                    else {
                        return [2 /*return*/, analyticsJsonResponse];
                    }
                }
                else {
                    console.warn("Super Properties not found.", analyticsJsonResponse);
                    return [2 /*return*/, analyticsJsonResponse];
                }
                return [2 /*return*/];
        }
    });
}); };
var instance;
export var userContext = {
    getInstance: function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!instance) return [3 /*break*/, 2];
                        return [4 /*yield*/, UserContext.initialize()];
                    case 1:
                        instance = _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/, instance];
                }
            });
        });
    }
};
