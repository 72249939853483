import SplashLogger from "utils/SplashLogger";
var Mixpanel = /** @class */ (function () {
    function Mixpanel() {
        this.init = function () {
            var _a;
            if ((_a = window.trackerProperties) === null || _a === void 0 ? void 0 : _a.analyticsEnabled) {
                window.getMixpanelInstance = function getMixpanelInstance(trackerId) {
                    if (window.mixpanel[trackerId] != null) {
                        return window.mixpanel[trackerId];
                    }
                    var mixpanelConfig = {
                        persistence: "localStorage",
                        persistence_name: trackerId,
                        debug: WebCoreUIConfig.env === "development"
                    };
                    window.mixpanel.init(window.webCoreUiRuntimeConfigs.thirdPartyTrackers.mixpanelToken, mixpanelConfig, trackerId);
                    window.mixpanel[trackerId].register({ "distinct_id": trackerId });
                    return window.mixpanel[trackerId];
                };
            }
            else {
                SplashLogger.warn("Mixpanel tracking not enabled");
            }
        };
        this.init();
    }
    return Mixpanel;
}());
export { Mixpanel };
