import * as ApiThrottler from "../../ApiThrottler";
import SplashLogger from "utils/SplashLogger";
import { routes } from "./utils/constants";
var loggerName = "BufferTrackApi";
var DataStore = {};
export function initalizeTrackServerSideApiThrottler() {
    if (DataStore.apiThrottlerPush) {
        return;
    }
    var transformFunction = function (payloadArray) {
        var data = {
            records: payloadArray,
            created_at: new Date().toISOString(),
        };
        return JSON.stringify(data);
    };
    DataStore.apiThrottlerPush = ApiThrottler.initializeAndGetPushFunction("TrackServerSide", routes.USER_EVENT_TRACK, transformFunction);
}
export function pushTrackServerSideEventsViaApiThrottler(data) {
    DataStore.event = data.event;
    DataStore.properties = data.properties;
    DataStore.only_clevertap = data.only_clevertap;
    DataStore.only_mixpanel = data.only_mixpanel;
    var payload = {
        "event": DataStore.event,
        "properties": DataStore.properties,
        "only_clevertap": DataStore.only_clevertap,
        "only_mixpanel": DataStore.only_mixpanel,
        "client_id": data.client_id
    };
    var pushFunction = DataStore.apiThrottlerPush;
    if (pushFunction) {
        pushFunction(payload);
        try {
            // Adding console logs additionally due to requirement from product team
            console.group('EVENT DISPATCHED (server-side)');
            console.info(DataStore.event);
            console.info(DataStore.properties);
            console.groupEnd();
            SplashLogger.debug(loggerName, 'EVENT DISPATCHED (server-side)', {
                event: DataStore.event,
                properties: DataStore.properties
            });
        }
        catch (err) {
            console.error('SOMETHING WENT WRONG in logging -- trackServerSide', err.message);
        }
        return;
    }
    else {
        SplashLogger.error(loggerName, "SOMETHING WENT WRONG -- ApiThrottler TrackServerSide not yet initialised");
    }
}
