var _a;
var LogLevel;
(function (LogLevel) {
    LogLevel[LogLevel["DEBUG"] = 1000] = "DEBUG";
    LogLevel[LogLevel["INFO"] = 2000] = "INFO";
    LogLevel[LogLevel["WARN"] = 3000] = "WARN";
    LogLevel[LogLevel["ERROR"] = 4000] = "ERROR";
})(LogLevel || (LogLevel = {}));
var LogLevelEnumKeys = Object.keys(LogLevel);
var LogLevelEnumValues = Object.values(LogLevel);
var getLogLevelValueFromString = function (logLevelString) {
    return LogLevelEnumValues[LogLevelEnumKeys.indexOf(logLevelString)];
};
var Loggers = (_a = {},
    _a[LogLevel.ERROR] = console.error,
    _a[LogLevel.WARN] = console.warn,
    _a[LogLevel.INFO] = console.info,
    _a[LogLevel.DEBUG] = console.debug,
    _a);
var logWithSeverityAndTime = function (logger, args, severity) {
    var timeStamp = new Date().toJSON();
    var message;
    if (args.length == 1) {
        if (args[0] instanceof Error) {
            message = JSON.stringify({
                name: args[0].name,
                message: args[0].message,
                stack: args[0].stack,
            });
        }
        else {
            message = args[0];
        }
    }
    else {
        message = args;
    }
    Loggers[severity]({
        "log.level": LogLevel[severity],
        "@timestamp": timeStamp,
        logger: logger || "SplashLogger",
        message: message,
    });
};
var SplashLogger = {
    error: function (logger) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        if (getLogLevelValueFromString(WebCoreUIConfig.logLevel) <= LogLevel.ERROR)
            logWithSeverityAndTime(logger, args, LogLevel.ERROR);
    },
    warn: function (logger) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        if (getLogLevelValueFromString(WebCoreUIConfig.logLevel) <= LogLevel.WARN)
            logWithSeverityAndTime(logger, args, LogLevel.WARN);
    },
    info: function (logger) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        if (!!window.webCoreUiDebugEnabled || (getLogLevelValueFromString(WebCoreUIConfig.logLevel) <= LogLevel.INFO))
            logWithSeverityAndTime(logger, args, LogLevel.INFO);
    },
    debug: function (logger) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        if (!!window.webCoreUiDebugEnabled || (getLogLevelValueFromString(WebCoreUIConfig.logLevel) <= LogLevel.DEBUG))
            logWithSeverityAndTime(logger, args, LogLevel.DEBUG);
    },
};
export default SplashLogger;
