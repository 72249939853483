var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as Sentry from "@sentry/browser";
import SplashLogger from "utils/SplashLogger";
var loggerName = 'SentryInitialize';
export var init = function (envSuffix, sampleRate, tracesSampleRate) {
    var sentryConfig = getSentryConfig(envSuffix, sampleRate, tracesSampleRate);
    SplashLogger.debug(loggerName, "SentryInitialize (".concat(envSuffix, ")"), sentryConfig);
    Sentry.init(sentryConfig);
};
export var initBrowserClientForWeb = function (envSuffix, sampleRate, tracesSampleRate) {
    // filter integrations that use the global variable
    var integrations = Sentry.getDefaultIntegrations({}).filter(function (defaultIntegration) {
        return !["BrowserApiErrors", "Breadcrumbs", "GlobalHandlers"].includes(defaultIntegration.name);
    });
    var baseUrl = WebCoreUIConfig.baseUrl;
    var allowedOriginsForSentry = WebCoreUIConfig.WebSentryIntegration.allowedOrigins;
    var beforeSend = function (e) {
        var matchUrl = false;
        try {
            var lastValidUrl_1 = getLastValidUrl(e);
            if (lastValidUrl_1) {
                matchUrl = lastValidUrl_1.startsWith(baseUrl) || allowedOriginsForSentry.some(function (origin) { return lastValidUrl_1.startsWith(origin); });
            }
        }
        catch (_a) {
            SplashLogger.error(loggerName, "Sentry URL match Error", e);
            matchUrl = true;
        }
        if (matchUrl) {
            return e;
        }
        else {
            SplashLogger.debug(loggerName, "Sentry URL mismatch", e);
            return null;
        }
    };
    function getLastValidUrl(event) {
        var frames;
        try {
            frames = event.exception.values[0].stacktrace.frames;
        }
        catch (e) {
            return null;
        }
        for (var i = frames.length - 1; i >= 0; i--) {
            var frame = frames[i];
            if (frame && frame.filename !== '<anonymous>' && frame.filename !== '[native code]') {
                return frame.filename || null;
            }
        }
        return null;
    }
    var additionalConfigs = {
        integrations: integrations,
        transport: Sentry.makeFetchTransport,
        stackParser: Sentry.defaultStackParser,
        beforeSend: beforeSend
    };
    var sentryConfig = __assign(__assign({}, getSentryConfig(envSuffix, sampleRate, tracesSampleRate)), additionalConfigs);
    SplashLogger.debug(loggerName, "BrowserClient Initialised (".concat(envSuffix, ")"), sentryConfig);
    var sentryBrowserClient = new Sentry.BrowserClient(sentryConfig);
    var scope = new Sentry.Scope();
    scope.setClient(sentryBrowserClient); // Assign client to scope
    sentryBrowserClient.init(); // Initialize the client
    return scope;
};
var getSentryConfig = function (envSuffix, sampleRate, tracesSampleRate) {
    var baseUrl = WebCoreUIConfig.baseUrl;
    var beforeSend = function (e) {
        var matchUrl = false;
        try {
            var lastValidUrl = getLastValidUrl(e);
            matchUrl = lastValidUrl && lastValidUrl.startsWith(baseUrl);
        }
        catch (_a) {
            SplashLogger.error(loggerName, "Sentry URL match Error", e);
            matchUrl = true;
        }
        if (matchUrl) {
            return e;
        }
        else {
            SplashLogger.debug(loggerName, "Sentry URL mismatch", e);
            return null;
        }
    };
    function getLastValidUrl(event) {
        var frames;
        try {
            frames = event.exception.values[0].stacktrace.frames;
        }
        catch (e) {
            return null;
        }
        for (var i = frames.length - 1; i >= 0; i--) {
            var frame = frames[i];
            if (frame && frame.filename !== '<anonymous>' && frame.filename !== '[native code]') {
                return frame.filename || null;
            }
        }
        return null;
    }
    var sentryConfig = {
        beforeSend: beforeSend,
        enabled: WebCoreUIConfig.sentry.enabled,
        dsn: WebCoreUIConfig.sentry.dsn,
        environment: WebCoreUIConfig.sentry.env + "-" + envSuffix,
        sampleRate: sampleRate || WebCoreUIConfig.sentry.sampleRate,
        tracesSampleRate: tracesSampleRate || WebCoreUIConfig.sentry.tracesSampleRate,
        release: WebCoreUIConfig.sentry.release,
        attachStacktrace: true
    };
    return sentryConfig;
};
