var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as Types from "./types";
import SplashLogger from "../../utils/SplashLogger";
import { getErrorInfo } from "../../utils";
import { withTimeout, Mutex } from "async-mutex";
import * as Sentry from "@sentry/browser";
// NOTE : Do not use this module for business critial events.
// Reason: Some events might be missed in cases of n/w failure
var Throttlers = {};
var loggerName = function (throttlerDataStore) {
    return "ApiThrottler - ".concat(throttlerDataStore.Id);
};
var bufferSizeText = function (throttlerDataStore) {
    return "bufferSize=".concat(throttlerDataStore.Buffer.length, "/").concat(throttlerDataStore.maxBufferSize);
};
var backOff = function (throttlerDataStore, maxBackoffIntervalSeconds, backoffFactor) {
    if (throttlerDataStore.bufferTimeIntervalMs < (maxBackoffIntervalSeconds * 1000)) {
        throttlerDataStore.bufferTimeIntervalMs =
            throttlerDataStore.bufferTimeIntervalMs * backoffFactor;
    }
    else if (throttlerDataStore.reportErrors) {
        throttlerDataStore.reportErrors("ApiThrottler ".concat(throttlerDataStore.Id, " - MaxBackoffInterval reached"), "warning", {
            url: throttlerDataStore.postApiEndpoint,
        });
    }
};
var getPushFunction = function (throttlerDataStore, warnBufferSizeBytes, maxBackoffIntervalSeconds, backoffFactor) {
    return function (payload) {
        SplashLogger.debug(loggerName(throttlerDataStore), "push - " + JSON.stringify(payload));
        throttlerDataStore.Buffer.push(__assign({}, payload));
        checkAndFlushBufferIfFilled(throttlerDataStore, warnBufferSizeBytes, maxBackoffIntervalSeconds, backoffFactor)();
    };
};
var getFlushBufferViaBeaconFunction = function (throttlerDataStore) { return function () {
    // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/sendBeacon
    if (throttlerDataStore.Buffer.length === 0) {
        SplashLogger.debug(loggerName(throttlerDataStore), "Buffer size is 0 in getFlushBufferViaBeaconFunction()");
        return;
    }
    if (document.visibilityState == "hidden") {
        var jsonBody = throttlerDataStore.BufferTransformFunction(throttlerDataStore.Buffer);
        if (jsonBody === null || jsonBody === undefined || Object.keys(jsonBody).length === 0) {
            SplashLogger.debug(loggerName(throttlerDataStore), "jsonBody is Blank in getFlushBufferViaBeaconFunction()");
            Sentry.captureMessage("jsonBody is Blank in getFlushBufferViaBeaconFunction()", {
                extra: { buffer: throttlerDataStore.Buffer, postApiEndpoint: throttlerDataStore.postApiEndpoint },
                level: "error",
            });
            return;
        }
        // Clearing the buffer here means some events might get lost in case where
        // sendBeacon is executed during a network failure.
        // it will require storing events in local storage and
        // the events we are sending are not business critical,
        // so even if we miss a few events due to an edge case, it is acceptable.
        // Refer: PF-502
        throttlerDataStore.Buffer = [];
        var headers = {
            type: 'application/json',
        };
        // Blob is used here to send additional custom headers
        // we needed to send data in application/json format
        // instead of the default text/plain format used by sendBeacon
        var blob = new Blob([jsonBody], headers);
        // https://xgwang.me/posts/you-may-not-know-beacon/#it-may-throw-error%2C-be-sure-to-catch
        var sendBeaconFn = navigator.sendBeacon.bind(navigator);
        var queueSuccess = sendBeaconFn(throttlerDataStore.postApiEndpoint, blob);
        if (!queueSuccess) {
            if (throttlerDataStore.reportErrors) {
                throttlerDataStore.reportErrors("ApiThrottler ".concat(throttlerDataStore.Id, " - send beacon queue failed"), "warning", {
                    url: throttlerDataStore.postApiEndpoint,
                    body: jsonBody,
                });
            }
            SplashLogger.error(loggerName(throttlerDataStore), "send beacon queue failed ; " + bufferSizeText(throttlerDataStore));
        }
    }
}; };
function setShouldFlushAfterDelay(throttlerDataStore, delayInMinutes) {
    return __awaiter(this, void 0, void 0, function () {
        var delayInMilliseconds;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    SplashLogger.debug(loggerName(throttlerDataStore), "setShouldFlushAfterDelay buffer : ".concat(JSON.stringify(throttlerDataStore.Buffer)));
                    delayInMilliseconds = delayInMinutes * 60 * 1000;
                    return [4 /*yield*/, new Promise(function (resolve) { return setTimeout(resolve, delayInMilliseconds); })];
                case 1:
                    _a.sent();
                    throttlerDataStore.shouldFlush = true;
                    return [2 /*return*/];
            }
        });
    });
}
function logUnauthorizedErrorInSentry(dataToBeFlushed, endPoint) {
    var sampleRate = WebCoreUIConfig.sentry.customSampleRate.unauthorizedApiCall;
    if (Math.random() < sampleRate) {
        Sentry.captureMessage("Unauthorized Error in Flush Buffer", {
            extra: { dataToBeFlushed: dataToBeFlushed, postApiEndpoint: endPoint },
            level: "info",
        });
    }
}
var flushBuffer = function (throttlerDataStore, maxBackoffIntervalSeconds, backoffFactor) { return function () { return __awaiter(void 0, void 0, void 0, function () {
    var mutexRelease, e_1, nextFlushAt_1, dataToBeFlushed, jsonBody, res, error_1, nextFlushAt;
    var _a, _b, _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                SplashLogger.debug(loggerName(throttlerDataStore), "flushBuffer ; " + bufferSizeText(throttlerDataStore));
                SplashLogger.debug(loggerName(throttlerDataStore), "buffer : ".concat(JSON.stringify(throttlerDataStore.Buffer)));
                _d.label = 1;
            case 1:
                _d.trys.push([1, 3, , 4]);
                return [4 /*yield*/, throttlerDataStore.timerMutex.acquire()];
            case 2:
                mutexRelease = _d.sent();
                return [3 /*break*/, 4];
            case 3:
                e_1 = _d.sent();
                SplashLogger.warn(loggerName(throttlerDataStore), "unable to acquire mutex " + bufferSizeText(throttlerDataStore) + "; error: " + e_1.name + ":" + e_1.message);
                return [2 /*return*/];
            case 4:
                clearTimeout(throttlerDataStore.TimeoutId);
                if (throttlerDataStore.Buffer.length == 0) {
                    nextFlushAt_1 = new Date(new Date().getTime() + throttlerDataStore.bufferTimeIntervalMs);
                    SplashLogger.debug(loggerName(throttlerDataStore), "empty; enqueuing next flush at" + nextFlushAt_1);
                    mutexRelease();
                    throttlerDataStore.TimeoutId = setTimeout(flushBuffer(throttlerDataStore, maxBackoffIntervalSeconds, backoffFactor), throttlerDataStore.bufferTimeIntervalMs);
                    return [2 /*return*/];
                }
                dataToBeFlushed = __spreadArray([], throttlerDataStore.Buffer, true);
                if (dataToBeFlushed.length === 0) {
                    SplashLogger.debug(loggerName(throttlerDataStore), "dataToBeFlushed size is 0 in flushBuffer()");
                    return [2 /*return*/];
                }
                throttlerDataStore.Buffer = [];
                jsonBody = throttlerDataStore.BufferTransformFunction(dataToBeFlushed);
                _d.label = 5;
            case 5:
                _d.trys.push([5, 8, , 9]);
                if (jsonBody === null || jsonBody === undefined || Object.keys(jsonBody).length === 0) {
                    SplashLogger.debug(loggerName(throttlerDataStore), "jsonBody is Blank in flushBuffer()");
                    Sentry.captureMessage("jsonBody is Blank in flushBuffer()", {
                        extra: { dataToBeFlushed: dataToBeFlushed, postApiEndpoint: throttlerDataStore.postApiEndpoint },
                        level: "error",
                    });
                    return [2 /*return*/];
                }
                if (!throttlerDataStore.shouldFlush) return [3 /*break*/, 7];
                return [4 /*yield*/, fetch(throttlerDataStore.postApiEndpoint, {
                        method: "POST",
                        mode: "cors",
                        credentials: "include",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                        },
                        body: jsonBody,
                    })];
            case 6:
                res = _d.sent();
                if (res.status == 200) {
                    throttlerDataStore.bufferTimeIntervalMs = throttlerDataStore.originalBufferTimeIntervalMs;
                }
                else if (res.status == 401) {
                    SplashLogger.debug(loggerName(throttlerDataStore), "Unauthorized Error in Flush Buffer : ".concat(JSON.stringify(dataToBeFlushed)));
                    throttlerDataStore.shouldFlush = false;
                    (_a = throttlerDataStore.Buffer).push.apply(_a, dataToBeFlushed);
                    logUnauthorizedErrorInSentry(dataToBeFlushed, throttlerDataStore.postApiEndpoint);
                    setShouldFlushAfterDelay(throttlerDataStore, 30);
                }
                else if (res.status >= 500) {
                    (_b = throttlerDataStore.Buffer).push.apply(_b, dataToBeFlushed);
                    backOff(throttlerDataStore, maxBackoffIntervalSeconds, backoffFactor);
                    if (throttlerDataStore.reportErrors) {
                        throttlerDataStore.reportErrors("ApiThrottler ".concat(throttlerDataStore.Id, " - non 200 response"), "warning", {
                            url: throttlerDataStore.postApiEndpoint,
                            body: jsonBody,
                        });
                    }
                    SplashLogger.error(loggerName(throttlerDataStore), "non-200 flush ; " + bufferSizeText(throttlerDataStore));
                }
                _d.label = 7;
            case 7: return [3 /*break*/, 9];
            case 8:
                error_1 = _d.sent();
                (_c = throttlerDataStore.Buffer).push.apply(_c, dataToBeFlushed);
                backOff(throttlerDataStore, maxBackoffIntervalSeconds, backoffFactor);
                if (throttlerDataStore.reportErrors) {
                    throttlerDataStore.reportErrors(error_1, "warning", {
                        url: throttlerDataStore.postApiEndpoint,
                        body: jsonBody,
                        customException: true,
                        customExceptionFrom: "ApiThrottler#flushBuffer"
                    });
                }
                SplashLogger.error(loggerName(throttlerDataStore), "n/w error flush ; " + bufferSizeText(throttlerDataStore) +
                    " error info = " +
                    JSON.stringify(getErrorInfo(error_1)));
                return [3 /*break*/, 9];
            case 9:
                nextFlushAt = new Date(new Date().getTime() + throttlerDataStore.bufferTimeIntervalMs);
                SplashLogger.debug(loggerName(throttlerDataStore), "processed; enqueuing next flush at" + nextFlushAt);
                mutexRelease();
                throttlerDataStore.TimeoutId = setTimeout(flushBuffer(throttlerDataStore, maxBackoffIntervalSeconds, backoffFactor), throttlerDataStore.bufferTimeIntervalMs);
                return [2 /*return*/];
        }
    });
}); }; };
var checkAndFlushBufferIfFilled = function (throttlerDataStore, warnBufferSizeBytes, maxBackoffIntervalSeconds, backoffFactor) { return function () { return __awaiter(void 0, void 0, void 0, function () {
    var bufferByteSize, isBufferFilled;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                SplashLogger.debug(loggerName(throttlerDataStore), "checkAndFlushBufferIfFilled - " + bufferSizeText(throttlerDataStore));
                bufferByteSize = new Blob([JSON.stringify(throttlerDataStore.Buffer)])
                    .size;
                if (throttlerDataStore.reportErrors &&
                    bufferByteSize >= warnBufferSizeBytes) {
                    SplashLogger.debug(loggerName(throttlerDataStore), "checkAndFlushBufferIfFilled - " + bufferSizeText(throttlerDataStore));
                    throttlerDataStore.reportErrors("ApiThrottler ".concat(throttlerDataStore.Id, " - WarnBufferSizeBytes"), "warning", {
                        actual: JSON.stringify(throttlerDataStore.Buffer).length,
                        allowed: warnBufferSizeBytes,
                    });
                }
                isBufferFilled = throttlerDataStore.Buffer.length >=
                    throttlerDataStore.maxBufferSize;
                if (!isBufferFilled) return [3 /*break*/, 2];
                return [4 /*yield*/, flushBuffer(throttlerDataStore, maxBackoffIntervalSeconds, backoffFactor)()];
            case 1:
                _a.sent();
                _a.label = 2;
            case 2: return [2 /*return*/];
        }
    });
}); }; };
export var initializeAndGetPushFunction = function (throttlerId, postApiEndpoint, bufferTransformFunction, maxBufferSize, bufferTimeIntervalMs, warnBufferSizeBytes, maxBackoffIntervalSeconds, backoffFactor, reportErrors) {
    SplashLogger.debug("ApiThrottler - ".concat(throttlerId), "initializeAndGetPushFunction called");
    maxBufferSize || (maxBufferSize = WebCoreUIConfig.ApiThrottler.Defaults.MaxBufferSize);
    bufferTimeIntervalMs || (bufferTimeIntervalMs = WebCoreUIConfig.ApiThrottler.Defaults.IntervalSeconds * 1000);
    warnBufferSizeBytes || (warnBufferSizeBytes = WebCoreUIConfig.ApiThrottler.Defaults.WarnBufferSizeBytes);
    maxBackoffIntervalSeconds || (maxBackoffIntervalSeconds = WebCoreUIConfig.ApiThrottler.Defaults.MaxBackoffIntervalSeconds);
    backoffFactor || (backoffFactor = WebCoreUIConfig.ApiThrottler.Defaults.BackoffFactor);
    if (Throttlers[throttlerId]) {
        return getPushFunction(Throttlers[throttlerId], warnBufferSizeBytes, maxBackoffIntervalSeconds, backoffFactor);
    }
    Throttlers[throttlerId] = {
        Id: throttlerId,
        Buffer: [],
        TimeoutId: null,
        BufferTransformFunction: bufferTransformFunction,
        postApiEndpoint: postApiEndpoint,
        maxBufferSize: maxBufferSize,
        bufferTimeIntervalMs: bufferTimeIntervalMs,
        originalBufferTimeIntervalMs: bufferTimeIntervalMs,
        reportErrors: reportErrors,
        timerMutex: withTimeout(new Mutex(), 100),
        shouldFlush: true
    };
    // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/sendBeacon
    typeof document !== "undefined" && document.addEventListener("visibilitychange", getFlushBufferViaBeaconFunction(Throttlers[throttlerId]));
    Throttlers[throttlerId].TimeoutId = setTimeout(flushBuffer(Throttlers[throttlerId], maxBackoffIntervalSeconds, backoffFactor), 0);
    return getPushFunction(Throttlers[throttlerId], warnBufferSizeBytes, maxBackoffIntervalSeconds, backoffFactor);
};
var DumpApiUrl = WebCoreUIConfig.services.DataDumpService.host +
    WebCoreUIConfig.services.DataDumpService.base +
    WebCoreUIConfig.services.DataDumpService.endpoints.dump;
// Now apiThrottlerConfig is getting overriden from config files in Web and web_core_ui.js in curriculum-playable service
export var initDumpServiceApiThrottler = function (throttlerName, recordType, created_by, apiThrottlerConfig) {
    apiThrottlerConfig || (apiThrottlerConfig = {});
    SplashLogger.debug("ApiThrottler", "initDumpServiceApiThrottler called by DumpService.".concat(throttlerName, ".").concat(recordType, ".").concat(created_by));
    var bufferTransformFunction = function (payloadArray) {
        var data = {
            type: recordType,
            priority: apiThrottlerConfig.priority || Types.DumpServicePriority.MEDIUM,
            records: payloadArray,
            created_at: new Date().toISOString(),
            created_by: created_by,
        };
        return JSON.stringify(data);
    };
    var pushFunction = initializeAndGetPushFunction("DumpService.".concat(throttlerName), apiThrottlerConfig.url ? apiThrottlerConfig.url : (WebCoreUIConfig.ApiThrottler.Url ? WebCoreUIConfig.ApiThrottler.Url : DumpApiUrl), bufferTransformFunction, apiThrottlerConfig.maxBufferSize, apiThrottlerConfig.intervalSeconds ? apiThrottlerConfig.intervalSeconds * 1000 : null, apiThrottlerConfig.warnBufferSizeBytes, apiThrottlerConfig.maxBackoffIntervalSeconds, apiThrottlerConfig.backoffFactor, apiThrottlerConfig.reportErrors);
    if (apiThrottlerConfig.shouldSetWindowVar) {
        window.ApiThrottlers || (window.ApiThrottlers = {});
        window.ApiThrottlers[throttlerName] = pushFunction;
    }
    return pushFunction;
};
